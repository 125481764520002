define("ember-file-upload/mirage/index", ["exports", "ember-cli-mirage/response", "ember-file-upload/mirage/utils"], function (_exports, _response, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upload = upload;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var NETWORK = {
    'wired': 50000,
    // 500 Mb/s
    'wifi': 15000,
    // 15 Mb/s
    'dsl': 1000,
    // 1 Mb/s
    '4g': 3000,
    // 4 Mb/s
    '3g': 250,
    // 250 kb/s
    '2g': 50,
    // 50 kb/s
    'gprs': 20,
    // 20 kb/s
    'offline': 0
  };
  function upload(fn) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      network: null,
      timeout: null
    };
    return function (db, request) {
      var _this = this;
      var speed = Infinity;
      if (NETWORK[options.network]) {
        speed = NETWORK[options.network] * 1024;
      }
      var _extractFormData = (0, _utils.extractFormData)(request.requestBody),
        file = _extractFormData.file,
        data = _extractFormData.data;
      var loaded = 0;
      var total = file.value.size;
      return new Ember.RSVP.Promise(function (resolve) {
        var start = new Date().getTime();
        var metadata = (0, _utils.extractFileMetadata)(file.value);
        var upload = function upload() {
          var timedOut = options.timeout && new Date().getTime() - start > options.timeout;
          if (timedOut || loaded >= total) {
            request.upload.onprogress({
              lengthComputable: true,
              total: total,
              loaded: Math.min(loaded, total)
            });
            metadata.then(function (metadata) {
              request.requestBody = Object.assign(_defineProperty({}, file.key, metadata), data);
              if (timedOut) {
                resolve(new _response.default(408));
                return;
              }
              resolve(fn.call(_this, db, request));
            }).catch(function (error) {
              resolve(new _response.default(500, {}, {
                error: error.message
              }));
            });
          } else {
            request.upload.onprogress({
              lengthComputable: true,
              total: total,
              loaded: loaded
            });
            loaded += speed / 20;
            setTimeout(upload, 50);
          }
        };
        upload();
      });
    };
  }
});