define("ember-svg-jar/inlined/feedback-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#fff\" d=\"M0 0h80v80H0z\"/><g clip-path=\"url(#clip0_892_23569)\"><path d=\"M33.51 1.41c28.58-3.8 47.85 19.7 46.41 42.58-1.1 17.43-14.27 31.57-31.63 34.88C26.38 83.06 2.36 68.34.19 44.57-1.82 22.6 12.12 4.25 33.51 1.41z\" fill=\"#FCD983\"/><path d=\"M41.74 48.52h-1.79a2.23 2.23 0 01-1.985-3.12 2.22 2.22 0 012.085-1.33 76.45 76.45 0 0016.74-1.46 2.222 2.222 0 11.86 4.36 81.657 81.657 0 01-15.91 1.55zM25.76 46.85a1.997 1.997 0 01-.47-.06 37.52 37.52 0 01-15.17-7.16 2.22 2.22 0 01-.38-3.12 2.221 2.221 0 013.13-.35 33.229 33.229 0 0013.38 6.31 2.23 2.23 0 011.69 2.65 2.22 2.22 0 01-2.18 1.73zM39.58 41.92a2.26 2.26 0 01-1.28-.41 2.22 2.22 0 01-.54-3.09 33.762 33.762 0 019.51-9 2.219 2.219 0 013.354 2.362 2.23 2.23 0 01-.974 1.388 29.13 29.13 0 00-8.25 7.79 2.221 2.221 0 01-1.82.96zM29.96 40.62a2.21 2.21 0 01-1.92-1.1 22.2 22.2 0 00-6.9-7.3 2.22 2.22 0 112.46-3.7 26.78 26.78 0 018.28 8.76 2.22 2.22 0 01-.8 3 2.17 2.17 0 01-1.12.34zM43.65 64.07a2.22 2.22 0 01-2-1.19c-2.05-3.91-5.13-6.24-8.25-6.24h-.05a10.46 10.46 0 00-9 5.61 2.22 2.22 0 01-3 1 2.21 2.21 0 01-1-3 14.999 14.999 0 0113-8.07h.08c4.81 0 9.35 3.22 12.19 8.63a2.222 2.222 0 01-.94 3 2.24 2.24 0 01-1.03.26z\" fill=\"#282B8F\"/></g><defs><clipPath id=\"clip0_892_23569\"><path fill=\"#fff\" transform=\"translate(0 1)\" d=\"M0 0h80v78.58H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "80",
      "height": "80",
      "viewBox": "0 0 80 80",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});