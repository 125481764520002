define("ember-svg-jar/inlined/bang", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1.94 8a1 1 0 01-.999-.938l-.375-6A1 1 0 011.564 0h.872a1 1 0 01.998 1.062l-.375 6A1 1 0 012.06 8h-.122zM2 12.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3z\" fill=\"#D33329\"/>",
    "attrs": {
      "width": "4",
      "height": "13",
      "viewBox": "0 0 4 13",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});