define("ember-svg-jar/inlined/feedback-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#fff\" d=\"M0 0h80v80H0z\"/><g clip-path=\"url(#clip0_892_23585)\"><path d=\"M33.51 1.41c28.59-3.8 47.86 19.7 46.42 42.58-1.1 17.43-14.27 31.57-31.63 34.88C26.38 83.06 2.37 68.34.19 44.57c-2-21.97 11.93-40.32 33.32-43.16z\" fill=\"#FCD983\"/><path d=\"M12.07 31.03a2.21 2.21 0 01-1.1-.29 2.22 2.22 0 01-.83-3 15.349 15.349 0 0111.77-7.58 15.39 15.39 0 0112.94 5.3 2.22 2.22 0 01-.26 3.13 2.212 2.212 0 01-3.13-.26 11.06 11.06 0 00-9.15-3.75A11 11 0 0014 29.93a2.22 2.22 0 01-1.93 1.1zM66.51 29.95a2.22 2.22 0 01-1.9-1.09 9.83 9.83 0 00-8.16-4.94 10.34 10.34 0 00-8.4 4.94 2.22 2.22 0 01-3.07.68 2.21 2.21 0 01-.68-3.06 14.679 14.679 0 0112.15-7 14.19 14.19 0 0112 7.13 2.21 2.21 0 01-.78 3 2.19 2.19 0 01-1.16.34z\" fill=\"#282B8F\"/><path d=\"M55.26 30.63a3.59 3.59 0 00-1.3 4.17 2.649 2.649 0 003.7 1.29 4.29 4.29 0 001.66-4.29c-.27-1.56-2.08-2.59-4.06-1.17z\" fill=\"#282B8F\"/><path d=\"M56.42 38.96a5.339 5.339 0 01-4.81-3.1 6.15 6.15 0 012.13-7.29 5.33 5.33 0 015.17-.8 4.72 4.72 0 012.87 3.6 6.79 6.79 0 01-2.86 6.9 5 5 0 01-2.5.69zm.31-6.27a1.211 1.211 0 00-.48 1.05.23.23 0 00.14.14c.298-.35.458-.799.45-1.26l-.11.07zM19.73 32.43a2.798 2.798 0 001.48 3.78 3.5 3.5 0 004.1-1.38 3 3 0 00-.2-3.51c-.85-.95-3.61-1.99-5.38 1.11z\" fill=\"#282B8F\"/><path d=\"M22.33 38.96a5.38 5.38 0 01-5.13-3.65 5 5 0 01.33-4.13 6.18 6.18 0 014.67-3.33 5.78 5.78 0 014.8 1.77 5.48 5.48 0 01.46 6.56 6.12 6.12 0 01-5.13 2.78zm-.35-5.34a.49.49 0 00.18.24 1 1 0 001-.38.56.56 0 00.06-.47 1 1 0 00-.51-.11c-.21.04-.48.31-.73.72zM38.99 56a27 27 0 01-7.9-1.44 2.22 2.22 0 01-1.48-2.77 2.23 2.23 0 012.77-1.48c6.48 2 8.35 1.52 15.2-.72a2.23 2.23 0 012.8 1.42 2.22 2.22 0 01-1.42 2.8C44.77 55.16 42.02 56 38.99 56z\" fill=\"#282B8F\"/></g><defs><clipPath id=\"clip0_892_23585\"><path fill=\"#fff\" transform=\"translate(0 1)\" d=\"M0 0h80v78.58H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "80",
      "height": "80",
      "viewBox": "0 0 80 80",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});