define("ember-svg-jar/inlined/feedback-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#fff\" d=\"M0 0h80v80H0z\"/><g clip-path=\"url(#clip0_892_23577)\"><path d=\"M33.51 1.41c28.59-3.8 47.86 19.7 46.41 42.58-1.1 17.43-14.27 31.57-31.62 34.88C26.38 83.06 2.36 68.34.19 44.57-1.82 22.6 12.12 4.25 33.51 1.41z\" fill=\"#FCD983\"/><path d=\"M11.23 38.68a2.241 2.241 0 01-1.678-.792 2.21 2.21 0 01-.261-2.458 18.17 18.17 0 0111.59-9.13 18.21 18.21 0 0114.52 2.66 2.24 2.24 0 01.56 3.09 2.23 2.23 0 01-3.09.57 13.678 13.678 0 00-10.92-2 13.669 13.669 0 00-8.72 6.87 2.22 2.22 0 01-2 1.19zM44.24 33.48a2.21 2.21 0 01-2.217-2.088 2.22 2.22 0 01.557-1.602 17.689 17.689 0 0112.64-5.84 17.71 17.71 0 0112.88 5.28 2.22 2.22 0 01-.05 3.14 2.22 2.22 0 01-3.14 0 13.18 13.18 0 00-9.59-3.93 13.15 13.15 0 00-9.42 4.35 2.23 2.23 0 01-1.66.69z\" fill=\"#282B8F\"/><path d=\"M53.11 34.4a4.14 4.14 0 01-2.42-.76 4.22 4.22 0 01-1.67-2.81 4.19 4.19 0 01.79-3.16l.37-.48.56-.23a7.21 7.21 0 00.76-.35c.39-.208.808-.36 1.24-.45a3.67 3.67 0 012.69.55 4.21 4.21 0 011.82 2.7 4.21 4.21 0 01-.6 3.2 4.24 4.24 0 01-2.76 1.75c-.258.035-.519.048-.78.04zM20.74 37.09a4.41 4.41 0 01-3.7-1.93 3.93 3.93 0 01.86-5.27c.19-.14.34-.27.48-.39.248-.213.508-.41.78-.59a4.23 4.23 0 012.38-.59 4.23 4.23 0 013.57 1.58 4.11 4.11 0 01-.13 4.77 5.39 5.39 0 01-3.49 2.34c-.246.05-.498.076-.75.08zM29.05 57.04a2.24 2.24 0 01-2.11-1.52 2.22 2.22 0 011.4-2.81 43.858 43.858 0 0110.64-2.18 2.23 2.23 0 012.39 2.05 2.221 2.221 0 01-2.05 2.38 39.45 39.45 0 00-9.56 2c-.232.064-.472.091-.71.08z\" fill=\"#282B8F\"/></g><defs><clipPath id=\"clip0_892_23577\"><path fill=\"#fff\" transform=\"translate(0 1)\" d=\"M0 0h80v78.58H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "80",
      "height": "80",
      "viewBox": "0 0 80 80",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});