define("ember-svg-jar/inlined/star-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<rect opacity=\".1\" width=\"40\" height=\"40\" rx=\"20\" fill=\"#1A91F0\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20 23.762l-3.754 2.169a.515.515 0 01-.695-.172.481.481 0 01-.058-.36l.96-4.144-3.28-2.803a.484.484 0 01-.047-.695.512.512 0 01.334-.166l4.348-.392 1.727-3.9a.512.512 0 01.93 0l1.727 3.9 4.348.392a.497.497 0 01.458.537.488.488 0 01-.17.324l-3.28 2.803.96 4.144a.493.493 0 01-.384.589.518.518 0 01-.37-.057L20 23.762z\" fill=\"#1A91F0\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});