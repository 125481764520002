define("ember-svg-jar/inlined/feedback-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#fff\" d=\"M0 0h80v80H0z\"/><g clip-path=\"url(#clip0_892_23595)\"><path d=\"M33.52 1.41c28.58-3.8 47.85 19.7 46.41 42.58-1.1 17.43-14.27 31.57-31.63 34.88C26.38 83.06 2.37 68.34.2 44.57-1.81 22.6 12.12 4.25 33.52 1.41z\" fill=\"#FCD983\"/><path d=\"M13.87 35.04a2.23 2.23 0 01-2.22-2.05 14.002 14.002 0 015.03-11.83 14 14 0 0112.64-2.53 14.05 14.05 0 019.24 9 2.22 2.22 0 01-1.43 2.8 2.22 2.22 0 01-2.8-1.43 9.65 9.65 0 00-6.27-6.11 9.66 9.66 0 00-8.58 1.72 9.649 9.649 0 00-3.44 8.05 2.22 2.22 0 01-2 2.39l-.17-.01zM68.17 31.32A2.23 2.23 0 0166 29.59c-1.91-8.39-7.68-8.78-10.07-8.57a9 9 0 00-7.87 7.82 2.23 2.23 0 01-3.935 1.2 2.22 2.22 0 01-.495-1.63A13.48 13.48 0 0155.52 16.6c5.71-.52 12.6 2.36 14.81 12a2.22 2.22 0 01-2.16 2.72z\" fill=\"#282B8F\"/><path d=\"M26.94 33.53h-.26a4.19 4.19 0 01-3.7-3.41c-.42-1.67-.56-7.5.47-9 1.18-1.67 4-1.57 4-1.57a3.81 3.81 0 013.35 1.81 10.53 10.53 0 010 9.49 4.45 4.45 0 01-3.86 2.68zM57.92 22.32a5.2 5.2 0 00-1.34 3.3 7.151 7.151 0 00.54 4c.36.6.71 1.24 1.53 1.35a2.1 2.1 0 001.55-.48c.357-.326.622-.74.77-1.2a8.46 8.46 0 00.06-4.86 2.87 2.87 0 00-1-1.85c-.68-.42-1.77-.66-2.11-.26z\" fill=\"#282B8F\"/><path d=\"M58.87 33.53c-.182.01-.366.01-.55 0a4.55 4.55 0 01-3.33-2.49v-.07a9.17 9.17 0 01-.9-5.45 7.67 7.67 0 011.92-4.79c1.34-1.61 3.85-1.36 5.5-.18a5.37 5.37 0 012 3.36 10.86 10.86 0 01-.16 6.29 5.32 5.32 0 01-1.5 2.26 4.63 4.63 0 01-2.98 1.07z\" fill=\"#282B8F\"/><path d=\"M30.1 47.16l24-2.47c-1.33 4.75-5.77 9.78-10.65 10.45-4.88.67-9.93-3.04-13.35-7.98z\" fill=\"#EEEFF7\"/><path d=\"M42.31 57.79c-5 0-10.24-3.33-14.29-9.15a2.54 2.54 0 01-.23-2.51 2.54 2.54 0 012-1.46l24-2.48a2.55 2.55 0 012.2.89 2.568 2.568 0 01.51 2.32c-1.49 5.32-6.47 11.43-12.76 12.29a12.3 12.3 0 01-1.43.1zm-7.14-8.57c2.63 2.47 5.52 3.77 7.94 3.44 2.59-.36 5.12-2.47 6.8-5l-14.74 1.56z\" fill=\"#282B8F\"/></g><defs><clipPath id=\"clip0_892_23595\"><path fill=\"#fff\" transform=\"translate(0 1)\" d=\"M0 0h80v78.58H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "80",
      "height": "80",
      "viewBox": "0 0 80 80",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});