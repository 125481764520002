define("ember-svg-jar/inlined/circle-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19 10a9 9 0 11-18 0 9 9 0 0118 0zm-8.94 2.854l4.647-4.647-1.414-1.414L9 11.086 6.707 8.793l-1.414 1.414 2.646 2.647a1.5 1.5 0 002.122 0z\" fill=\"#25B869\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});