define("ember-svg-jar/inlined/swipe-pointer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M21.403 18c-1.63 0-2.967 1.327-2.967 2.948V31.09c-.46-.987-1.284-2.053-2.319-2.314-1.408-.38-3.131.738-3.524 2.037-.2.725-.07 1.549.324 2.556 1.928 4.603 4.3 9.022 7.188 12.377a.755.755 0 00.556.253h10.016a.75.75 0 00.44-.15c1.068-.785 1.93-2.124 2.644-3.718a21.058 21.058 0 001.542-5.25c.28-1.806.279-3.496-.116-4.997-.382-1.448-1.41-2.654-2.945-2.832a4.883 4.883 0 00-1.066.103c-.19-.451-.458-.837-.789-1.07a2.657 2.657 0 00-1.576-.507c-.44.007-.84.166-1.194.415-.16-.242-.324-.494-.534-.645a2.709 2.709 0 00-1.6-.507c-.384 0-.756.106-1.113.254v-6.149c-.031-1.972-1.597-2.95-2.968-2.947h.001zm0 1.474a1.46 1.46 0 011.484 1.473v7.737a.762.762 0 00.576.729.767.767 0 00.839-.407c.164-.338.781-.69 1.182-.69.18 0 .514.074.73.23.217.156.383.338.383.875a.76.76 0 00.57.715c.32.076.683-.09.833-.382.302-.599.463-.696.834-.702.103-.002.463.067.696.23.233.165.406.353.406.875a.753.753 0 00.446.682.764.764 0 00.806-.14c.281-.138.557-.18.916-.173.884.111 1.344.662 1.657 1.761.317 1.11.329 2.695.07 4.364-.26 1.668-.771 3.42-1.426 4.881-.614 1.37-1.366 2.44-2.029 2.994h-9.355c-2.604-3.051-5.091-7.736-6.712-11.686-.338-.862-.368-1.357-.29-1.634.275-.713 1.044-1.115 1.704-1.014.789.296.984.818 1.321 1.417l1.473 2.936c.15.291.513.457.833.38a.76.76 0 00.57-.714V20.948c0-.83.648-1.474 1.483-1.474z\" fill=\"#BEC4D5\" stroke=\"#BEC4D5\" stroke-width=\".5\"/>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});