define("ember-svg-jar/inlined/document-checked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.704 32.1h1.118a.6.6 0 00.57-.414l1.101-3.37h5.023l1.092 3.37a.6.6 0 00.571.414h1.118a.6.6 0 00.566-.797l-4.522-13a.6.6 0 00-.567-.403h-1.539a.6.6 0 00-.566.402l-4.532 13a.6.6 0 00.567.798zm5.3-11.515l1.88 5.785h-3.768l1.889-5.785z\" fill=\"#1A91F0\" stroke=\"#1A91F0\" stroke-width=\".2\"/><path d=\"M2 6a4 4 0 014-4h15.843a4 4 0 012.829 1.172L31.5 10l6.23 5.814A4 4 0 0139 18.738V46a4 4 0 01-4 4H6a4 4 0 01-4-4V6z\" stroke=\"#D9DEEB\" stroke-width=\"3\"/><path d=\"M23.5 2v11a4 4 0 004 4h11\" stroke=\"#D9DEEB\" stroke-width=\"3\"/><rect x=\"8\" y=\"36\" width=\"23\" height=\"3\" rx=\"1.5\" fill=\"#1A91F0\"/><rect x=\"8\" y=\"41\" width=\"23\" height=\"3\" rx=\"1.5\" fill=\"#1A91F0\"/><circle cx=\"40.5\" cy=\"47.5\" r=\"9.5\" fill=\"#2094F0\" stroke=\"#fff\" stroke-width=\"2\"/><path d=\"M36.5 47.5l3 3 5-5\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"bevel\"/>",
    "attrs": {
      "width": "51",
      "height": "58",
      "viewBox": "0 0 51 58",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});