define("ember-svg-jar/inlined/nav-forward", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M.432 2.257L1.783.783l5.893 5.48a1 1 0 010 1.474l-5.893 5.45-1.351-1.475L5.52 7 .43 2.257z\" fill=\"#1E2532\"/>",
    "attrs": {
      "width": "8",
      "height": "14",
      "viewBox": "0 0 8 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});