define("ember-svg-jar/inlined/OK-match-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g opacity=\".6\"><path opacity=\".3\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 22.8c5.965 0 10.8-4.835 10.8-10.8 0-5.965-4.835-10.8-10.8-10.8C6.035 1.2 1.2 6.035 1.2 12c0 5.965 4.835 10.8 10.8 10.8z\" fill=\"#EC930C\"/></g><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.897 10.097l-5.576 5.576a1.8 1.8 0 01-2.545 0L6.6 12.497 8.297 10.8l2.752 2.752L16.2 8.4l1.697 1.697z\" fill=\"#EC930C\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});