define("ember-svg-jar/inlined/chat-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle fill=\"#06C\" r=\"48%\" cx=\"50%\" cy=\"50%\"/><svg fill=\"#FFF\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"-949 951 100 100\" transform=\"matrix(-.69 0 0 .69 84.5 15.5)\"><path d=\"M-862.7 991.4v5.6c0 10.8-8.8 19.6-19.6 19.6h-22.3l-5.1 3.3c1.9 4 6 6.8 10.7 6.8h17.3l15.4 9.8c.7.5 1.6-.2 1.4-1l-2.1-8.8h3.6c6.6 0 11.9-5.3 11.9-11.9v-11.5c0-6.4-4.9-11.5-11.2-11.9z\"/><path d=\"M-882.3 965.4h-48.2c-8.9 0-16.1 7.2-16.1 16.1V997c0 8.9 7.2 16.1 16.1 16.1h4.8l-2.8 11.9c-.2.9.5 1.6 1.3 1.6.2 0 .5-.1.7-.2l20.8-13.2h23.4c8.9 0 16.1-7.2 16.1-16.1v-15.6c0-8.9-7.2-16.1-16.1-16.1zm-38.9 28.3c-2.4 0-4.4-2-4.4-4.4 0-2.4 2-4.4 4.4-4.4 2.4 0 4.4 2 4.4 4.4 0 2.4-1.9 4.4-4.4 4.4zm14.8 0c-2.4 0-4.4-2-4.4-4.4 0-2.4 2-4.4 4.4-4.4 2.4 0 4.4 2 4.4 4.4.1 2.4-1.9 4.4-4.4 4.4zm14.9 0c-2.4 0-4.4-2-4.4-4.4 0-2.4 2-4.4 4.4-4.4 2.4 0 4.4 2 4.4 4.4 0 2.4-2 4.4-4.4 4.4z\"/></svg>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});