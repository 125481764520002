define("ember-svg-jar/inlined/star-hover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.136 1.484a1 1 0 011.728 0L19.951 8.5a1 1 0 00.652.474l7.936 1.718a1 1 0 01.534 1.644l-5.41 6.055a1 1 0 00-.25.767l.819 8.078a1 1 0 01-1.398 1.016l-7.43-3.274a1 1 0 00-.807 0l-7.43 3.274a1 1 0 01-1.399-1.016l.818-8.078a1 1 0 00-.249-.767l-5.41-6.055a1 1 0 01.534-1.644l7.936-1.718a1 1 0 00.652-.474l4.087-7.016z\" fill=\"#EC930C\"/>",
    "attrs": {
      "width": "30",
      "height": "29",
      "viewBox": "0 0 30 29",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});