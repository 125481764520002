define("ember-svg-jar/inlined/nav-back", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.568 2.257L6.217.783.324 6.263a1 1 0 000 1.474l5.893 5.45 1.351-1.475L2.48 7l5.09-4.743z\" fill=\"#1E2532\"/>",
    "attrs": {
      "width": "8",
      "height": "14",
      "viewBox": "0 0 8 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});