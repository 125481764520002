define("ember-file-upload/components/file-dropzone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "OJCP4wtH",
    "block": "[[[18,1,[[28,[37,1],null,[[\"supported\",\"active\",\"valid\"],[[30,0,[\"supported\"]],[30,0,[\"active\"]],[30,0,[\"valid\"]]]]],[30,0,[\"queue\"]]]]],[\"&default\"],false,[\"yield\",\"hash\"]]",
    "moduleName": "ember-file-upload/components/file-dropzone/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});