define("ember-svg-jar/inlined/feedback-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#fff\" d=\"M0 0h80v80H0z\"/><g clip-path=\"url(#clip0_892_23605)\"><path d=\"M33.52 1.41c28.58-3.8 47.85 19.7 46.41 42.58-1.1 17.43-14.27 31.57-31.63 34.88C26.38 83.06 2.37 68.34.2 44.57-1.81 22.6 12.13 4.25 33.52 1.41z\" fill=\"#FCD983\"/><path d=\"M22.08 32.67c-.182 0-.363-.02-.54-.06a2.54 2.54 0 01-2-3c1.13-5.27 4.74-8.9 9.64-9.73 4.59-.77 9.23 1.07 11.29 4.47a2.54 2.54 0 01-.85 3.49 2.55 2.55 0 01-3.49-.86c-1-1.59-3.64-2.51-6.11-2.09-1.65.28-4.57 1.4-5.51 5.78a2.54 2.54 0 01-2.43 2zM51.34 23.07a2.54 2.54 0 01-2.53-2.38 10.13 10.13 0 015.17-9.26c3.48-1.93 7.66-1.49 11.47 1.22a2.54 2.54 0 01-.12 4.222 2.53 2.53 0 01-2.82-.082c-2.22-1.58-4.31-1.9-6.06-.92a5.06 5.06 0 00-2.57 4.5 2.54 2.54 0 01-2.38 2.69l-.16.01z\" fill=\"#282B8F\"/><path d=\"M35.5 39.16c13.35-6.12 16.6-8.15 28.59-10 1.9 4.37-.64 11.22-4.15 14.92-3.51 3.7-7.19 5.95-12.56 5.22A14 14 0 0135.5 39.16z\" fill=\"#EEEFF7\"/><path d=\"M49.34 51.91a18.138 18.138 0 01-2.27-.15 16.63 16.63 0 01-14-11.89 2.55 2.55 0 011.37-3l4.53-2.09c9.81-4.55 13.87-6.44 24.72-8.14a2.53 2.53 0 012.72 1.49c1.88 4.31.67 12.11-4.63 17.69-2.78 2.88-6.64 6.09-12.44 6.09zM38.77 40.43a11.43 11.43 0 009 6.3c3.93.53 6.83-.72 10.38-4.46 2.4-2.53 4.31-7 4.09-10.28-8.47 1.49-12.22 3.23-21.08 7.35l-2.39 1.09z\" fill=\"#282B8F\"/></g><defs><clipPath id=\"clip0_892_23605\"><path fill=\"#fff\" transform=\"translate(0 1)\" d=\"M0 0h80v78.58H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "80",
      "height": "80",
      "viewBox": "0 0 80 80",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});