define("ember-svg-jar/inlined/hint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 10a8 8 0 1016 0 8 8 0 00-16 0zm15 0a7 7 0 11-14 0 7 7 0 0114 0zm-4.54-1.98c0 .902-.463 1.357-.93 1.816-.475.467-.955.938-.955 1.889H9.08c0-1.417.632-1.999 1.15-2.477.363-.334.67-.617.67-1.098 0-.585-.39-.975-1.04-.975-.585 0-1.04.455-1.04 1.235H7.26c.065-1.625 1.105-2.665 2.6-2.665 1.625 0 2.6.975 2.6 2.275zm-2.6 4.355a.86.86 0 00-.845.845c0 .455.39.845.845.845a.86.86 0 00.845-.845.86.86 0 00-.845-.845z\" fill=\"#1A91F0\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});