define("ember-svg-jar/inlined/BAD-match-badge--hover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 22.8c5.965 0 10.8-4.835 10.8-10.8 0-5.965-4.835-10.8-10.8-10.8C6.035 1.2 1.2 6.035 1.2 12c0 5.965 4.835 10.8 10.8 10.8z\" fill=\"#DA0C22\"/><path d=\"M11.94 14a1 1 0 01-.999-.938l-.375-6A1 1 0 0111.564 6h.871a1 1 0 01.999 1.062l-.375 6a1 1 0 01-.998.938h-.121zM12 18.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});